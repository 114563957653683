import { Accordion } from "@szhsin/react-accordion";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type {
  HomeroomListing,
  WritingTaskListing,
} from "@/application/domain/HomeroomListing";
import { Page } from "@/application/ui/lib/Page";
import { LoadingPage } from "@/application/ui/lib/spinner/LoadingPage";
import { TimeoutPage } from "@/application/ui/lib/Timeout/TimeoutPage";
import { theme } from "@/application/ui/theme/theme";

import { useCreateSection } from "../hooks/useCreateSection";
import { useTeacherHomeroomDashboard } from "../hooks/useTeacherDashboard";
import { DashboardSection } from "./Section/DashboardSection";
import { SectionHeaderEdition } from "./Section/SectionHeaderEdition";

interface Props {
  homeroomId: string;
  openCreationFor: (homeroomId: string, sectionId?: string) => void;
  openUpdateForDraft: (
    homeroom: HomeroomListing,
    writingTaskId: string,
    sectionId?: string,
  ) => void;
  onOpenGradePanel: (task: WritingTaskListing, homeroomName: string) => void;
  onSectionCreationCompleted: () => void;
  isCreatingSection: boolean;
  includeMasked: boolean;
}

export const TeacherDashboardSections = ({
  homeroomId,
  openCreationFor,
  openUpdateForDraft,
  onOpenGradePanel,
  onSectionCreationCompleted,
  isCreatingSection,
  includeMasked,
}: Props) => {
  const { t } = useTranslation();
  const { dashboard, isError: isErrorDashboard } = useTeacherHomeroomDashboard(
    homeroomId,
    includeMasked,
  );

  const { createSection: createSectionMutation } = useCreateSection();

  const onCreateSection = (name: string) => {
    void createSectionMutation({ name }).then(onSectionCreationCompleted);
  };

  if (isErrorDashboard) {
    return (
      <Page>
        <TimeoutPage />
      </Page>
    );
  }

  if (!dashboard) {
    return (
      <Page>
        <LoadingPage />
      </Page>
    );
  }

  return (
    <Group aria-labelledby={dashboard.id}>
      {isCreatingSection && (
        <SectionHeaderEdition
          onCancel={onSectionCreationCompleted}
          onSubmit={onCreateSection}
        />
      )}
      <Accordion allowMultiple>
        {dashboard.sections.map((section, index) => (
          <DashboardSection
            initialEntered={index === 0}
            homeroomId={dashboard.id}
            section={section}
            key={section.id}
            openCreationFor={() => openCreationFor(dashboard.id, section.id)}
            openUpdateForDraft={(taskId) =>
              openUpdateForDraft(dashboard, taskId, section.id)
            }
            onOpenGradePanel={(task) => onOpenGradePanel(task, dashboard.name)}
          />
        ))}
        <DashboardSection
          initialEntered={dashboard.sections.length === 0}
          homeroomId={dashboard.id}
          hideActions
          section={{
            id: "0",
            name: t("sections.unclassified"),
            tasks: dashboard.sectionlessTasks,
          }}
          openCreationFor={() => openCreationFor(dashboard.id)}
          openUpdateForDraft={(taskId) => openUpdateForDraft(dashboard, taskId)}
          onOpenGradePanel={(task) => onOpenGradePanel(task, dashboard.name)}
        />
      </Accordion>
    </Group>
  );
};

const Group = styled.section`
  &.separator {
    border-bottom: 1px solid ${theme.colors.gray[300]};
  }
`;
