import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";

type FlagsConfig = {
  maintenance: boolean;
};

export const FlagsContext = createContext<FlagsConfig>({
  maintenance: false,
});

enum ConfigCatKeys {
  maintenance = "maintenance",
}

export const useFlags = () => {
  const context = useContext(FlagsContext);

  if (!context) {
    throw new Error("useFlags cannot be used outside FlagsProvider");
  }

  return context;
};

type Props = {
  getFlag: (
    key: ConfigCatKeys,
    defaultValue: boolean,
  ) => { value: boolean; loading: boolean };
} & PropsWithChildren;

export const FlagsProvider = ({ children, getFlag }: Props) => {
  const maintenance = getFlag(ConfigCatKeys.maintenance, false).value;

  return (
    <FlagsContext.Provider
      value={{
        maintenance,
      }}
    >
      {children}
    </FlagsContext.Provider>
  );
};
